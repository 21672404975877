import React from "react";
import SubPageTitle from '../components/sub-page-title/subPageTitle';
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionDescription from "../components/section-description/section-description";
import '../css/polityka-prywatnosci.scss';
import Button from "../components/button/button";

export default class PolitykaPrywatnosci extends React.Component {
  
  render(){
    return <Layout>
      <SEO 
        title="Thanks"
        description="We will contact with you soon" 
        url="https://jointoweb.pl/dziekujemy-za-wypelnienie-briefu"
        lang="pl" 
      />
      <div className="section section--brief">
        <div className="section-brief--title section-brief--title--relative">
          <SubPageTitle text={<span>Thanks <strong>for completing the brief</strong></span>}/>
          <div className="section-text--description">
            <SectionDescription content="We have sent you a message with a link, so you can confirm your email address. After considering the implementation of the project you will be presented with a ready plan."/>
          </div>
          <Button text="See offer" url="/" />
        </div>
      </div>
    </Layout>
  }
}