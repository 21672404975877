import React from "react";
import PropTypes from "prop-types";
import "./section-description.scss";

const SectionDescription = ({content}) => (
    <p className="section-text">
        {content}
    </p>
);

SectionDescription.propTypes = {
    content: PropTypes.string,
}
  
SectionDescription.defaultProps = {
    content: null,
}

export default SectionDescription