import React from "react";
import PropTypes from "prop-types"

import "./button.scss";

function extraClasses(classes){
    return `button ${classes}`;
}

const Button = ({text,url,cssClass,clickCallback,disabled}) => {
    return <a href={url} className={extraClasses(cssClass)} onClick={clickCallback} disabled={disabled}>
        {text}
    </a>
}

Button.propTypes = {
    text: PropTypes.string,
    cssClass: PropTypes.string,
    clickCallback: PropTypes.func,
    disabled: PropTypes.bool
}
  
Button.defaultProps = {
    text: '',
    cssClass: '',
    clickCallback: () => {},
    disabled: false,
}

export default Button
